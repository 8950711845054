import React from "react";

function PortfolioDetails({ value }) {
  return (
    <section id="portfolio-details" className="portfolio-details">
      <div className="container" data-aos="fade-up">
        <div className="row gy-4">
          <div className="col-lg-8">
            <div className="slides-1 portfolio-details-slider swiper">
              <div className="swiper-wrapper align-items-center">
                <div className="swiper-slide">
                  <img src={"/"+value?.img} alt />
                </div>
                
              </div>
              <div className="swiper-pagination" />
            </div>
            <div className="portfolio-description">
              <h2>{value?.title}</h2>
              <p>
                {value?.discription}
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="portfolio-info">
              <h3>Our Approach</h3>
              <ul>
                {value?.approch?.map((v, i) => {
                  return (
                    <li>
                      <strong>{v?.strong}</strong>: {v?.para}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="portfolio-info">
              <h3>Our Services Include</h3>
              <ul>
                {value?.service?.map((v, i) => {
                  return (
                    <li>
                      <strong>{v?.strong}</strong>: {v?.para}
                    </li>
                  );
                })}
              </ul>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default PortfolioDetails;
