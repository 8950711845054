import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_wrai31s", "template_rubz0fi", form.current, {
        publicKey: "nLcWsCyIGaysxahNT",
      })
      .then(
        () => {
          toast("Thank you for reaching out. We will get back to you shortly.")
          form.current.reset(); 
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <section id="contact" className="contact">
      <div className="container position-relative" data-aos="fade-up">
        <div className="row gy-4 d-flex justify-content-end">
          <div className="col-lg-5" data-aos="fade-up" data-aos-delay={100}>
            <div className="info-item d-flex">
              <i className="bi bi-geo-alt flex-shrink-0" />
              <div>
                <h4>Location:</h4>
                <p>Vikash Vihar Colony, Patna Bihar , 801506</p>
              </div>
            </div>
            {/* End Info Item */}
            <div className="info-item d-flex">
              <i className="bi bi-envelope flex-shrink-0" />
              <div>
                <h4>Email:</h4>
                <p>hittheshubham1810@gmail.com</p>
              </div>
            </div>
            {/* End Info Item */}
            <div className="info-item d-flex">
              <i className="bi bi-phone flex-shrink-0" />
              <div>
                <h4>Call:</h4>
                <p>7762042085</p>
              </div>
            </div>
            {/* End Info Item */}
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay={250}>
            <form ref={form} onSubmit={sendEmail} className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="number"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Your Phone No."
                    required
                  />
                </div>
                <div className="col-md-12 form-group pt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  rows={5}
                  placeholder="Message"
                  required
                  defaultValue={""}
                />
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message" />
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
          {/* End Contact Form */}
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default ContactForm;
