import React, {useEffect} from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import PortfolioDetails from '../components/PortfolioDetails'
import PortfolioCard from '../components/PortfolioCard'

function Portfolio() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <main id="main">
        <Breadcrumbs value={{ currentPage: "Portfolio", bg: "assets/img/portfolio-header.jpg" }} />
        {/* <PortfolioDetails/> */}
        <PortfolioCard/>
    </main>
  )
}

export default Portfolio