import React from 'react'
import {Link} from "react-router-dom"
function Breadcrumbs({value}) {
  console.log(value)
  return (
    <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage: `url(${value?.bg})`}}>
  <div className="container position-relative d-flex flex-column align-items-center">
    <h2>{value?.currentPage}</h2>
    <ol>
      {value?.prevPage ? <li><Link to="/services">{value?.prevPage}</Link></li>: <li><Link to="/">Home</Link></li>}
      
      <li>{value?.currentPage}</li>
    </ol>
  </div>
</div>

  )
}

export default Breadcrumbs