import React from 'react'

function OurServices() {
  const serviceData = [
    {
      title:"Web Development",
      icon:"bi bi-briefcase",
      color: "#f57813",
      discription:"From custom websites to e-commerce platforms, we specialize in crafting high-performance, visually captivating web solutions tailored to your unique needs."
      
    },
    {
      title:"App Development",
      icon:"bi bi-card-checklist",
      color: "#15a04a",
      discription:"Transform your ideas into powerful mobile apps that captivate users and enhance their digital experience. Our team excels in iOS, Android, and cross-platform app development."
    },
    {
      title:"UI/UX Design",
      icon:"bi bi-bar-chart",
      color: "#d90769",
      discription:"Elevate your brand with intuitive, visually striking user interfaces and seamless user experiences. We combine creativity with usability to create designs that resonate with your audience."
    },
    {
      title:"Digital Marketing",
      icon:"bi bi-calendar4-week",
      color: "#15bfbc",
      discription:"Boost your online presence and reach new heights with our strategic digital marketing services. We've covered everything from SEO and social media marketing to content creation and PPC campaigns."
    },
  ]
  return (
    <section id="services-list" className="services-list">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Our Services</h2>
            </div>
            <div className="row gy-5">
              {serviceData.map((v, i)=>{
                return(
                  <div className="col-lg-6 col-md-6 service-item d-flex" data-aos="fade-up" data-aos-delay={(i+1)*100}>
                <div className="icon flex-shrink-0">
                  <i className={v?.icon} style={{ color: v?.color }} />
                </div>
                <div>
                  <h4 className="title">
                    <a href="#" className="stretched-link">
                      {v?.title}
                    </a>
                  </h4>
                  <p className="description">
                    {v?.discription}
                  </p>
                </div>
              </div>
                )
              })}
              
              
            </div>
          </div>
        </section>
  )
}

export default OurServices