import React from "react";

import Slider from "react-slick";
function WhyChooseUs() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const WhyChooseUsData = [
    {
      title: "Let's grow your business together",
      discription:
        "Empower your business with customized software solutions from Soft Tech Gem. We combine creativity, tech, and expertise for your success in the digital era.",
    },
    {
      title: "Our Vision",
      discription:
        "Our vision is to revolutionize the digital landscape by creating stunning, user-centric experiences that inspire and engage audiences worldwide. We strive to be pioneers in technology, constantly pushing boundaries to deliver exceptional solutions.",
    },
    {
      title: "Our Mission",
      discription:
        "At Soft Tech Gem, our mission is simple yet ambitious: to empower businesses and individuals with cutting-edge digital solutions that drive growth, enhance brand visibility, and exceed expectations. We are committed to excellence, integrity, and customer satisfaction.",
    },
  ];
  return (
    <section id="why-us" className="why-us">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Why Choose Us</h2>
        </div>
        <div className="row g-0 " data-aos="fade-up" data-aos-delay={200} style={{ background: "#F7F9FC" }}>
          <div className="col-xl-5 img-bg" style={{ backgroundImage: 'url("assets/img/why-us-bg.jpg")' }} />

          <div className="col-xl-6  slides my-auto mx-auto ">
            <Slider {...settings}>
              {WhyChooseUsData?.map((v, i) => {
                return (
                  <div className="swiper-slide">
                    <div className="item">
                      <h3 className="mb-3 mx-3 mt-3">{v?.title}</h3>
                      <h4 className="mb-3 mx-3">{v?.discription}</h4>
                    </div>
                  </div>
                );
              })}
            </Slider>

            <div className="swiper-button-prev" />
            <div className="swiper-button-next" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
