import React,{useEffect} from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import PortfolioDetails from '../components/PortfolioDetails'
import OurTeam from '../components/OurTeam'
function Team() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <main id="main">
        <Breadcrumbs value={{ currentPage: "Team", bg: "assets/img/team-header.jpg" }} />
        <OurTeam/>
    </main>
  )
}

export default Team