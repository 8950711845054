import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Footer from './components/Footer';
import React, { useEffect } from 'react';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Team from './pages/Team';
import ContactForm from './components/ContactForm';
import ContactUs from './pages/ContactUs';
import {Routes, Route} from "react-router-dom"
import DetailServicePage from './pages/DetailServicePage';
function App() {
 
  return (
    <div>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<AboutUs/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/portfolio" element={<Portfolio/>}/>
        <Route path="/team" element={<Team/>}/>
        <Route path="/contact" element={<ContactUs/>}/>
        <Route path="/:serviceName/:id" element={<DetailServicePage/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
