import React,{useEffect} from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import AboutSection2 from '../components/AboutSection2'
import WhyChooseUs from '../components/WhyChooseUs'
import CallToAction from '../components/CallToAction'
import OurTeam from '../components/OurTeam'

function AboutUs() {
   useEffect(() => {
      window.scrollTo(0, 0); // Scrolls to the top of the page
    }, []);
  return (
     <main id="main">
        <Breadcrumbs value={{ currentPage: "About", bg: "assets/img/about-header.jpg" }} />
        {/* <AboutSection2/> */}
        <WhyChooseUs/>
        <div className='py-4'></div>
        <CallToAction/>
        <OurTeam/>
     </main>
        
    
  )
}

export default AboutUs