import React, { useState } from "react";

function PortfolioCard() {
  const productItem = [
    {
      img: "assets/img/portfolio/manish.jpg",
      link: "https://manishdesignstudio.in/",
      name: "Manish Design Studio",
      category: "Web",
      discription: "Create a one-stop e-commerce platform offering a diverse range of professionally designed logos, posters, and banners. Elevate your brand with our customizable and eye-catching designs tailored for every need.",
    },
    // {
    //   img: "assets/img/portfolio/overseas.jpeg",
    //   link: "https://play.google.com/store/apps/details?id=ai.overseas",
    //   name: "Overseas.ai",
    //   category: "App",
    //   discription: "Introducing our job portal app, designed to connect migrant workers with overseas employment opportunities. Simplify your job search and secure your future with trusted employers around the world.",
    // },
    {
      img: "assets/img/portfolio/elearning.jpeg",
      link: "https://www.linkedin.com/posts/shubham-singh-6569791ab_freelancedeveloper-reactnative-elearningapp-activity-7192913683771985920-DOgu?utm_source=share&utm_medium=member_android",
      name: "Elearing (Buy Now)",
      category: "App",
      discription: "Introducing our e-learning app featuring live classes, FAQs, notes, recorded videos, and more.",
    },
    // {
    //   img: "assets/img/portfolio/tal.png",
    //   link: "https://www.facebook.com/thinkagainlab",
    //   name: "Think Again Lab",
    //   category: "Digital Marketing",
    //   discription: "",
    // },
    // {
    //   img: "assets/img/portfolio/avirose.jpg",
    //   link: "https://onclicksolution.com/",
    //   name: "Avirose",
    //   category: "Web",
    //   discription: "Explore our e-commerce platform for a curated collection of handmade goods, crafted with love and authenticity. Support artisans and find unique, one-of-a-kind items that add a personal touch to your life.",
    // },
    {
      img: "assets/img/portfolio/natureMonk.jpeg",
      link: "https://naturemonkshop.com/",
      name: "Nature Monk",
      category: "Web",
      discription: "Discover our e-commerce website offering a variety of organic dairy products, artisanal masalas, and traditional achar. Experience the taste of purity and tradition with our carefully curated selection of natural and wholesome foods.",
    },
    // {
    //   img: "assets/img/portfolio/egales.png",
    //   link: "https://eagleseye.co.in/",
    //   name: "Eagles Eye",
    //   category: "Digital Marketing",
    //   discription: "",
    // },
  ];
  const category = ["All", "Web", "App"];

  const [activeCat, setActiveCat] = useState("All");
  return (
    <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">
        <div
          className="portfolio-isotope"
          data-portfolio-filter="*"
          data-portfolio-layout="masonry"
          data-portfolio-sort="original-order"
        >
          <ul className="portfolio-flters" data-aos="fade-up" data-aos-delay={100}>
            {category?.map((v, i) => {
              return (
                <li data-filter="*" onClick={() => setActiveCat(v)} className={activeCat == v && " filter-active"}>
                  {v}
                </li>
              );
            })}
          </ul>
          {/* End Portfolio Filters */}
          <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay={300}>
            {productItem
              ?.filter((v, i) => {
                return v?.category ==  activeCat || activeCat ==  "All" 
              })
              ?.map((v, i) => {
                return (
                  <div className="col-lg-4 col-md-6 portfolio-item filter-app p-3 shadow">
                    <div className="d-flex justify-content-center">
                      <img src={v?.img} className="img-fluid " alt style={{ height: "400px" }} />
                    </div>
                    <div className="portfolio-info">
                      <h4>{v?.name}</h4>
                      <p>{v?.discription}</p>
                      {/* <a
                      href="assets/img/portfolio/app-1.jpg"
                      title="App 1"
                      data-gallery="portfolio-gallery-app"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-zoom-in" />
                    </a> */}
                      <a href={v?.link} target="blank" title="More Details" className="details-link">
                        <i className="bi bi-link-45deg" />
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* End Portfolio Container */}
        </div>
      </div>
    </section>
  );
}

export default PortfolioCard;
