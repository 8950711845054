import React from "react";
import {Link} from "react-router-dom"
function HeroSection() {
  return (
    <section id="hero" className="hero d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <h2 data-aos="fade-up">
              Grow your business with us
              <br />
            </h2>
            <blockquote data-aos="fade-up" data-aos-delay={100}>
              <p>
                At Soft Tech Gem, we specialize in providing a comprehensive range of digital solutions to help
                businesses thrive online. From web development to app creation, UI/UX design, and digital marketing
                services, we have the expertise to elevate your online presence and drive success.
              </p>
            </blockquote>
            <div className="d-flex" data-aos="fade-up" data-aos-delay={200}>
              <Link to="/contact" className="btn-get-started">
                Get Started
              </Link>
              {/* <a
                href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                className="glightbox btn-watch-video d-flex align-items-center"
              >
                <i className="bi bi-play-circle" />
                <span>Watch Video</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
