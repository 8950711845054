import React,{useEffect} from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import AboutSection2 from "../components/AboutSection2";
import WhyChooseUs from "../components/WhyChooseUs";
import CallToAction from "../components/CallToAction";
import OurTeam from "../components/OurTeam";
import OurServices from "../components/OurServices";
import ServiceCards from "../components/ServiceCards";
import Testimonials from "../components/Testimonials";
import PortfolioDetails from "../components/PortfolioDetails";
import {useLocation, useParams} from "react-router-dom"
function DetailServicePage() {
    const location = useLocation();
    const params = useParams();
  const cardDetails = [
    {
      title: "Web Development",
      img: "assets/img/webDevelopment.jpeg",
      //   approch:[": ",": ",":"],
      approch: [
        {
          strong: "Custom Design",
          para: "We create unique designs reflecting your brand identity and engaging your target audience.",
        },
        {
          strong: "Responsive Development",
          para: "Our websites are built with responsive design principles for seamless device functionality.",
        },
        {
          strong: "Scalable Solutions",
          para: " We deliver scalable web solutions to accommodate your business growth and future expansions.",
        },
      ],
      service: [
        { strong: "Website Design", para: "Create visually stunning and user-friendly websites." },
        {
          strong: "E-commerce Development",
          para: "Our websites are built with responsive design principles for seamless device functionality.",
        },
        {
          strong: "Custom Web Applications",
          para: "Develop tailored web apps for process automation and enhanced user experiences.",
        },
        { strong: "CMS Integration", para: " Integrate powerful content management systems for easy content updates." },
      ],
      discription:
        "Our web development services offer tailored solutions to meet your business objectives effectively. Whether you require a visually appealing website, a robust e-commerce platform, or a custom web application, we have the expertise to bring your vision to life.",
    },
    {
      title: "App Development",
      img: "assets/img/appDevelopment.jpeg",
      approch: [
        {
          strong: "User-Centric Design",
          para: " Design: Prioritize UX/UI design for intuitive and visually appealing apps.",
        },
        {
          strong: "Cross-Platform Compatibility",
          para: "Develop apps for iOS, Android, and cross-platform environments.",
        },
        {
          strong: "Performance Optimization",
          para: "Optimize app performance for speed and reliability.",
        },
      ],
      service: [
        { strong: "Discovery Phase", para: "Understand business goals and app requirements." },
        {
          strong: "Design and Prototyping",
          para: "Create wireframes and prototypes.",
        },
        {
          strong: "Development and Testing",
          para: "Build and test apps for quality and functionality.",
        },
        { strong: "Deployment and Maintenance", para: "Deploy apps and provide ongoing support." },
      ],
      discription:
        "Our app development services focus on user-centric design and cross-platform compatibility to ensure maximum reach and engagement.",
    },
    {
      title: "UI/UX Design",
      img: "assets/img/uiuxDesign.jpeg",
      approch: [
        {
          strong: "User-Centered Approach",
          para: "Design interfaces based on user needs and preferences.",
        },
        {
          strong: "Visual Design Excellence",
          para: "Blend creativity with usability for engaging designs.",
        },
        {
          strong: "Usability Testing",
          para: "Conduct thorough testing for a seamless user experience.",
        },
      ],
      service: [
        { strong: "Research and Analysis", para: "Gather insights for informed design decisions." },
        {
          strong: "Wireframing and Prototyping",
          para: "Visualize user flows and interface elements",
        },
        {
          strong: "Visual Design",
          para: "Develop high-fidelity designs with attention to detail.",
        },
        { strong: "Usability Testing and Iteration", para: "Gather feedback and iterate designs for optimal user experience." },
      ],
      discription:
        "Our UI/UX design services focus on creating engaging and visually appealing designs that enhance usability and user experience.",
    },
    {
      title: "Digital Marketing",
      img: "assets/img/digitalMarketing.jpeg",
      approch: [
        {
          strong: "Search Engine Optimization (SEO)",
          para: " Improve organic search rankings for increased visibility.",
        },
        {
          strong: "Social Media Marketing",
          para: "Engage with your audience and build brand awareness.",
        },
        {
          strong: "Content Marketing",
          para: " Create valuable content to attract and engage your target audience.",
        },
        {
            strong: "Pay-Per-Click (PPC) Advertising",
            para: "Drive immediate traffic and conversions through targeted campaigns.",
          },
      ],
      service: [
        { strong: "Strategy Development", para: "Define clear goals, target audience, and KPIs." },
        {
          strong: "Campaign Execution",
          para: "Implement strategic campaigns across digital channels.",
        },
        {
          strong: "Analytics and Optimization",
          para: "Monitor performance and optimize strategies for maximum ROI.",
        },
        { strong: "Reporting and Insights", para: "Provide regular reports and actionable insights for informed decision-making." },
      ],
      discription:
        "Our digital marketing services help boost brand visibility, drive traffic, generate leads, and maximize conversions.",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <main id="main">
      <Breadcrumbs
        value={{  currentPage: cardDetails[params.id - 1].title,  prevPage: "Services", bg: "/assets/img/services-header.jpg" }}
      />
      <PortfolioDetails value={cardDetails[params.id-1]} />
    </main>
  );
}

export default DetailServicePage;
