import React,{useEffect} from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import AboutSection2 from '../components/AboutSection2'
import WhyChooseUs from '../components/WhyChooseUs'
import CallToAction from '../components/CallToAction'
import OurTeam from '../components/OurTeam'
import OurServices from '../components/OurServices'
import ServiceCards from '../components/ServiceCards'
import Testimonials from '../components/Testimonials'

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <main id="main">
        <Breadcrumbs value={{ currentPage: "Services", bg: "assets/img/services-header.jpg" }} />
        <OurServices/>
        <ServiceCards/>
        {/* <Testimonials/> */}
        {/* <AboutSection2/>
        <WhyChooseUs/>
        <div className='py-5'></div>
        <CallToAction/>
        <OurTeam/> */}
     </main>
  )
}

export default Services