import React,{useEffect} from "react";
import HeroSection from "../components/HeroSection";
import WhyChooseUs from "../components/WhyChooseUs";
import OurServices from "../components/OurServices";
import CallToAction from "../components/CallToAction";
import Features from "../components/Features";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <div>
      <HeroSection/>
      <main id="main">
        <WhyChooseUs/>
        <OurServices/>
        <CallToAction/>
        {/* <Features/>  */}
      </main>
    </div>
  );
}

export default Home;
