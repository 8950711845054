import React from "react";
import {Link} from "react-router-dom"
function Footer() {
  const navItem = [
    {
      name: "Home",
      icon: "fa fa-home",
      path: "/",
    },
    {
      name: "About",
      icon: "fa fa-info-circle",
      path: "/about",
    },
    {
      name: "Services",
      icon: "fa fa-cogs",
      path: "/services",
    },
    {
      name: "Portfolio",
      icon: "fa fa-laptop",
      path: "/portfolio",
    },
    {
      name: "Team",
      icon: "fa fa-users",
      path: "/team",
    },
    {
      name: "Contact Us",
      icon: "fa fa-phone",
      path: "/contact",
    },
  ];
  return (
    <footer id="footer" className="footer">
      <div className="footer-content">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="index.html" className="logo d-flex align-items-center">
                <span>Soft Tech Gem</span>
              </a>
              <p>
                Unlock Your Digital Potential with Comprehensive Solutions. Expertise in Web & App Development, UI/UX
                Design, and Digital Marketing Strategies.
              </p>
              <div className="social-links d-flex  mt-3">
                <a href="https://wa.me/7762042085"  target="blank" className="twitter">
                  <i className="bi bi-whatsapp" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61558092595017" target="_blank" className="facebook">
                  <i className="bi bi-facebook" />
                </a>
                <a href="https://www.instagram.com/softtechgem?igsh=ZXpuZ2hkNXc5aGNu"  target="_blank" className="instagram">
                  <i className="bi bi-instagram" />
                </a>
                <a href="https://www.linkedin.com/company/soft-tech-gem/" target="_blank" className="linkedin">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                {navItem?.map((v, i) => {
                  return (
                    <li>
                      <i className="bi bi-dash" /> <Link to={v?.path}>{v?.name}</Link>
                    </li>
                  );
                })}

                
              </ul>
            </div>
            <div className="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bi bi-dash" /> <Link to="/web-development/1">Web Development</Link>
                </li>
                <li>
                  <i className="bi bi-dash" /> <Link to="/app-development/2">App Development</Link>
                </li>
                <li>
                  <i className="bi bi-dash" /> <Link to="/ui-ux-design/3">UI/UX Design</Link>
                </li>

                <li>
                  <i className="bi bi-dash" /> <Link to="/digital-marketing/4">Digital Marketing</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                Vikash Vihar Colony <br />
                801506, Patna
                <br />
                Bihar <br />
                <br />
                <strong>Phone:</strong> 7762042085, 9163507576
                <br />
                <strong>Email:</strong> team.softtechgem@gmail.com
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-legal">
        <div className="container">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>Soft Tech Gem</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            {/* All the links in the footer should remain intact. */}
            {/* You can delete the links only if you purchased the pro version. */}
            {/* Licensing information: https://bootstrapmade.com/license/ */}
            {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nova-bootstrap-business-template/ */}
            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
