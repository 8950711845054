import React from 'react'
import {Link} from "react-router-dom"
function CallToAction() {
  return (
    <section id="call-to-action" className="call-to-action">
    <div className="container" data-aos="fade-up">
      <div className="row justify-content-center">
        <div className="col-lg-6 text-center">
          <h3>Elevate Your Digital Presence</h3>
          <p>
          Transforming ideas into captivating digital experiences. Let's create your success story together.
          </p>
          <Link className="cta-btn" to="/contact">
            Get Started
          </Link>
        </div>
      </div>
    </div>
  </section>
  )
}

export default CallToAction