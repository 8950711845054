import React from "react";

function OurTeam() {
  const teamDetails = [
    {
      img: "assets/img/team/team1.jpeg",
      name: "Trupti Paunikar",
      designation: "MERN Stack Developer",
      fb:"",
      insta:"",
      linkdin:"https://www.linkedin.com/in/trupti-paunikar-550775243?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      img: "assets/img/team/team2.jpg",
      name: "Shuvadeep Das",
      designation: "Digital Marketer",
      fb:"https://www.facebook.com/shuvadeep.das.50",
      insta:"https://www.instagram.com/ig_shuvadeepdas?igsh=ZGRnbzYzZmttcTF5",
      linkdin:"https://www.linkedin.com/in/shuvadeep-das-928458229?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      img: "assets/img/team/team4.jpg",
      name: "Sharmistha Naskar",
      designation: "UI/UX Designer",
      fb:"https://www.facebook.com/profile.php?id=100063811595323&mibextid=ZbWKwL",
      insta:"",
      linkdin:"",
    },
    {
      img: "assets/img/team/team31.jpeg",
      name: "Shubham Singh",
      designation: "Web & App Developer",
      fb:"https://www.facebook.com/profile.php?id=100016748835401",
      insta:"https://www.instagram.com/shubhamsingh6569?igsh=MXh6bnBmb3NydGN3cg==",
      linkdin:"https://www.linkedin.com/in/shubham-singh-6569791ab?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
  ];
  return (
    <section id="team" className="team">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Our Team</h2>
        </div>
        <div className="row gy-4">
          {teamDetails?.map((v, i) => {
            return (
              <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay={(i + 1) * 100}>
                <div className="team-member">
                  <div className="member-img">
                    <img src={"/"+v?.img} style={{height:"380px", width:"100%"}} className="img-fluid" alt />
                    <div className="social">
                      {/* <a href="">
                        <i className="bi bi-twitter" target="blank"/>
                      </a> */}
                      <a href={v?.fb}>
                        <i className="bi bi-facebook" target="blank"/>
                      </a>
                      <a href={v?.insta}>
                        <i className="bi bi-instagram" target="blank"/>
                      </a>
                      <a href={v?.linkdin} target="blank">
                        <i className="bi bi-linkedin" />
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>{v?.name}</h4>
                    <span>{v?.designation}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default OurTeam;
