import React, {useEffect} from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import PortfolioDetails from '../components/PortfolioDetails'
import OurTeam from '../components/OurTeam'
import ContactForm from '../components/ContactForm'
function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  return (
    <main id="main">
        <Breadcrumbs value={{ currentPage: "Contact", bg: "assets/img/contact-header.jpg" }} />
        <ContactForm/>
    </main>
  )
}

export default ContactUs